
import { defineComponent, reactive, ref, toRefs } from "vue";
import { FileTextOutlined } from "@ant-design/icons-vue";
import TableWrapper from "@/views/Payment/components/TableWrapper.vue";
import searchTitle from "../components/searchTitle.vue";
import uploadFile from '../components/uploadFileFinance.vue'
import BatchConfirmModal from "../components/batchConfirmModal.vue";
import useSampleCheckFl from "@/hooks/checking/spotChecking/sampleCheck/useSampleCheckFl";
import {
  spotCheckSampledOptions,
  controllingSampledOptions,
  checkStatusOptions,
} from "../components/selectOptions";
import downloadFile from "@/utils/payment/downloadFile";
// import uploadFile from "@/views/Checking/spotChecking/spotCheckingVonder/sampleCheck/components/uploadFileFl.vue";
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const isOpen = ref<boolean>(false);
    const {
      column,
      data,
      dataRes,
      query,
      summary,
      getTableData,
      pagination,
      payoutRound,
      pageChange,
      sizeChange,
      offerTypeList,
      getOfferTypeList,
      total
    } = useSampleCheckFl();
    const init = () => {
      getOfferTypeList();
      getTableData();
    };
    init();
    const search = () => {
      getTableData();
    };
    const batchConfirmRef = ref();
    const handleBatchConfirmShow = () => {
      batchConfirmRef.value.search();
    };
    const reset = () => {
      query.checkStatus = [];
      query.model = "";
      query.programCode = "";
      query.programName = "";
      query.spotCheckSampled = [];
      query.vin = "";
      query.offerType = ''
      search();
    };
    const handleExportSampleData = () => {
      const params = {
        url: `/claimapi/SpotChecking/excelByProgram`,
        method: "post",
        params: {
          payoutRoundId: query.payoutRoundId,
          // payoutRoundId: '8a8246227b95a217017b9641b1150028'
        },
      };
      downloadFile(params);
    };
    const uploadVisible = ref(false);
    const handleUpload = () => {
      uploadVisible.value = true;
    };
    const handleTableChange = () => {
      search();
    };
    const closeUpload = (param: { isUploadVisible: boolean }) => {
      uploadVisible.value = param.isUploadVisible;
    };
    return {
      column,
      data,
      query,
      ...toRefs(query),
      summary,
      search,
      reset,
      isOpen,
      ...toRefs(pagination),
      isPagination: true,
      spotCheckSampledOptions,
      controllingSampledOptions,
      checkStatusOptions,
      payoutRound,
      batchConfirmRef,
      handleBatchConfirmShow,
      pageChange,
      sizeChange,
      offerTypeList,
      uploadVisible,
      handleExportSampleData,
      handleUpload,
      handleTableChange,
      closeUpload,
      total
    };
  },
  components: {
    searchTitle,
    TableWrapper,
    // FileTextOutlined,
    BatchConfirmModal,
    uploadFile,
  },
});
