import { ref, reactive, computed, onMounted, toRaw } from "vue";
import { useStore } from "vuex";
import { getSampleCheckDetailFlList } from "@/API/checking/spotChecking";
import { getIncentiveTypeDropDownSelectList } from "@/API/spotCheckingConfiguration";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal";
import useOverflowText from "@/utils/payment/useOverflowText";
type Key = ColumnProps["key"];
const useControllingChecked = () => {
  const column = ref([
    { title: "Program Name(EN)", dataIndex: "programName", customRender: useOverflowText() },
    { title: "Program Code", dataIndex: "programCode" },
    { title: "Offer Type", dataIndex: "offerType" },
    { title: "VIN", dataIndex: "vin" },
    { title: "Model Name", dataIndex: "modelName" },
    { title: "Claim Amount", dataIndex: "claimAmount", customRender: useThousandsAndTwoDecimal() },
    { title: "Spot Sampled", slots: { customRender: "spotSampled" } },
    {
      title: "Con. Reject History",
      slots: { customRender: "conRejectHistory" },
    },
    { title: "Spot Check Result", dataIndex: "spotCheckResult" },
    { title: "Sample Check Result", dataIndex: "sampleCheckResult" },
    { title: "Check Status", dataIndex: "checkStatus" },
    // { title: "Operation", slots: { customRender: "operation" }, width: 150 },
  ]);
  const query = reactive({
    checkStatus: [],
    model: "",
    payoutRoundId: "",
    programCode: "",
    programName: "",
    offerType: '',
    spotCheckSampled: [],
    type: "",
    vin: "",
  });
  // 分页
  const total = ref(0);
  const pagination = reactive({
    pageNumber: 1,
    pageSize: 10,
    total: 0
  });

  const { state } = useStore();
  const payoutRound = ref();
  console.log(state.spotChecking.sampleCheckPayoutRound);
  query.payoutRoundId = state.spotChecking.sampleCheckPayoutRound.payoutRoundId;
  query.type = state.spotChecking.sampleCheckPayoutRound.type;
  payoutRound.value = state.spotChecking.sampleCheckPayoutRound.payoutRound;
  const dataRes = ref();
  const data = computed(() => {
    return dataRes.value?.content ? dataRes.value?.content : [];
  });
  const summary = computed(() => {
    const obj = {};
    if (dataRes.value) {
      for (const key in dataRes.value) {
        if (key !== "spotCheckVinRespVOList") {
          obj[key] = dataRes.value[key];
        }
      }
    }
    return obj;
  });
  const getTableData = () => {
    const _query = {
      ...toRaw(query),
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize
    };
    getSampleCheckDetailFlList(_query).then((res) => {
      if (res) {
        dataRes.value = res;
        total.value = res.totalElements;
      } else {
        dataRes.value = []
        total.value = 0
      }
      
    });
  };
  // 分页方法
  const pageChange = (page: number, size: number) => {
    pagination.pageNumber = page > 0 ? page : 1;
    pagination.pageSize = size;
    getTableData();
  };
  const sizeChange = (page: number, size: number) => {
    pagination.pageNumber = page > 0 ? page : 1;
    pagination.pageSize = size;
    getTableData();
  };
  // offer type list
  const offerTypeRes = ref();
  const offerTypeList = computed(() => {
    if (offerTypeRes.value) {
      return  offerTypeRes.value?.incentiveCategory[0].offerType.filter((item: any) => {
        return item.nameEn.indexOf('F&A') !== -1
      })
    } else {
      return []
    }
  });
  const getOfferTypeList = () => {
    getIncentiveTypeDropDownSelectList().then((res) => {
      offerTypeRes.value = res;
    });
  };
  return {
    data,
    dataRes,
    column,
    query,
    summary,
    pagination,
    total,
    getTableData,
    payoutRound,
    pageChange,
    sizeChange,
    offerTypeList,
    getOfferTypeList
  };
};
export default useControllingChecked;
